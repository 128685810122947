import {useEffect} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import DataProvider from './context/DataProvider/DataProvider';

import NioScrollToTop from './components/NioScrollToTop/NioScrollToTop';
import HomeProjectManagement from './pages/home-page';

import InnerAbout from './pages/inner-pages/about';
import InnerAboutSolution from './pages/inner-pages/about-solution';
import InnerFeature from './pages/inner-pages/features';
import InnerFeaturesSolution from './pages/inner-pages/features-solution';
import InnerCustomerTestimonial from './pages/inner-pages/customer-testimonial';
import InnerContact from './pages/inner-pages/contact';
import InnerContactSolution from './pages/inner-pages/contact-solution';
import InnerPricing from './pages/inner-pages/pricing';
import InnerPricingSolution from './pages/inner-pages/pricing-solution';
import InnerBlogs from './pages/inner-pages/blogs';
import InnerBlogDetails from './pages/inner-pages/blog-details';
import InnerHelpCenter from './pages/inner-pages/help-center';
import InnerHelpCenterDetails from './pages/inner-pages/help-center-details';
import InnerCareer from './pages/inner-pages/career';
import InnerCareerDetails from './pages/inner-pages/career-details';
import InnerTerms from './pages/inner-pages/terms';
import InnerCareerTwo from './pages/inner-pages/career-project-manage';
import InnerCareerDetailsTwo from './pages/inner-pages/career-details-project-manage';
import InnerShop from './pages/inner-pages/shop';
import InnerProductDetails from './pages/inner-pages/product-details';

import MiscNotFound from './pages/404';
import MiscLogin from './pages/auth/login';
import MiscSignup from './pages/auth/sign-up';
import MiscResetPassword from './pages/auth/reset-password';
import MiscForgotPassword from './pages/auth/forgot-password';
import PrivacyPolicyPage from "./pages/inner-pages/terms/PrivacyPolicy";


function AppRoutes() {

    return (
        <Routes>

            <Route path="/" element={<HomeProjectManagement/>}/>

            <Route path='/about' element={<InnerAbout/>}/>
            <Route path='/about-solution' element={<InnerAboutSolution/>}/>
            <Route path='/features' element={<InnerFeature/>}/>
            <Route path='/features-solution' element={<InnerFeaturesSolution/>}/>
            <Route path='/customer-testimonials' element={<InnerCustomerTestimonial/>}/>
            <Route path='/contact-us' element={<InnerContact/>}/>
            <Route path='/contact-us-solution' element={<InnerContactSolution/>}/>
            <Route path='/pricing' element={<InnerPricing/>}/>
            <Route path='/pricing-solution' element={<InnerPricingSolution/>}/>
            <Route path='/blogs' element={<InnerBlogs/>}/>
            <Route path='/blog-details' element={<InnerBlogDetails/>}/>
            <Route path='/help-center' element={<InnerHelpCenter/>}/>
            <Route path='/help-center-details' element={<InnerHelpCenterDetails/>}/>
            <Route path='/careers' element={<InnerCareer/>}/>
            <Route path='/career-details' element={<InnerCareerDetails/>}/>
            <Route path='/terms-and-conditions' element={<InnerTerms/>}/>
            <Route path='/privacy-policy' element={<PrivacyPolicyPage/>}/>
            <Route path='/careers-two' element={<InnerCareerTwo/>}/>
            <Route path='/careers-two-details' element={<InnerCareerDetailsTwo/>}/>
            <Route path='/shop' element={<InnerShop/>}/>
            <Route path='/product-details' element={<InnerProductDetails/>}/>

            {/* miscellaneous pages */}
            <Route path="/404" element={<MiscNotFound/>}/>
            <Route path='/auth/login' element={<MiscLogin/>}/>
            <Route path='/auth/sign-up' element={<MiscSignup/>}/>
            <Route path='/auth/reset-password' element={<MiscResetPassword/>}/>
            <Route path='/auth/forgot-password' element={<MiscForgotPassword/>}/>

        </Routes>
    );
}


function App() {

    useEffect(() => {
        document.body.classList.add('nl-body');
    }, []);

    return (
        <DataProvider>
            <BrowserRouter>
                <NioScrollToTop/>
                <AppRoutes/>
            </BrowserRouter>
        </DataProvider>
    );
}


export default App;
