import React from 'react';
import classNames from 'classnames';
import {Link, useLocation} from 'react-router-dom';
import {Col, Container, Row} from 'react-bootstrap';

// context
import {useLayout} from '../../context/LayoutProvider/LayoutProvider';

// custom hooks
import useRouteMatch from '../../hooks/useRouteMatch';


// components
import {Icon, NioBrand, NioMedia} from '../../components';


export default function Footer({variant = 5, children}) {

    const layout = useLayout();
    const location = useLocation();
    const brandLogo = layout.footerLogos[location.pathname];
    const [logoName, logoVariant] = (typeof brandLogo === 'string' && brandLogo.split('.')) || ['s1', 'dark'];

    const compClasses = classNames({
        [`${useRouteMatch("/") ? "nl-footer-landing" : "nl-footer"}`]: true,
    });


    return (
        <footer className={compClasses}>
            <>
                <div className="nl-footer-top">
                    <Container>
                        <Row
                            className="nl-footer-content  justify-content-lg-between flex-lg-row-reverse gy-5 gy-md-7 pt-5 pt-md-7 pb-5 pb-md-7 border-bottom">
                            <Col lg="8" xl="7" xxl="6">
                                <Row className="gy-5 gy-lg-0 justify-content-between">
                                    <Col md="4" xl="6">
                                        <div className="nl-footer-info">
                                            <h5 className="mb-3 mb-md-4">Contact Info</h5>
                                            <ul className="row gy-1 gy-sm-3">
                                                <li className="col-12">
                                                    <Link to="mailto:info@primetarget.ge"
                                                          className="fs-7 text-capitalize d-flex align-items-center gap-1">
                                                        <Icon name="mail"/>
                                                        <span className="text-lowercase">
                                          info@PrimeTarget.com
                                        </span>
                                                    </Link>
                                                </li>
                                                <li className="col-12">
                                                    <Link to="tel:+1-800-222-4545"
                                                          className="fs-7 text-capitalize d-flex align-items-center gap-1">
                                                        <Icon name="call-alt"/>
                                                        <span className="text-lowercase">
                                          +995598202094
                                        </span>
                                                    </Link>
                                                </li>
                                                <li className="col-12">
                                                    <Link to="https://www.google.com/maps" target="_blank"
                                                          className="fs-7 text-capitalize d-flex align-items-center gap-1">
                                                        <Icon name="map-pin"/>
                                                        <span className="text-lowercase">
                                          2 Chavchavadze Street, Tbilisi, Georgia
                                        </span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col xs="6" md="3">
                                        <div className="nl-footer-info">
                                            <h5 className="mb-3 mb-md-4">Pages</h5>
                                            <ul className="row gy-1 gy-sm-3">
                                                <li className="col-12">
                                                    <Link className="fs-7 text-capitalize"
                                                          to="/features">Features</Link>
                                                </li>
                                                <li className="col-12">
                                                    <Link className="fs-7 text-capitalize" to="/about">About</Link>
                                                </li>
                                                <li className="col-12">
                                                    <Link className="fs-7 text-capitalize" to="/pricing">Pricing</Link>
                                                </li>
                                                <li className="col-12">
                                                    <Link className="fs-7 text-capitalize" to="/blogs">Blog</Link>
                                                </li>
                                                <li className="col-12">
                                                    <Link className="fs-7 text-capitalize" to="/blog-details">Blog
                                                        Post</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col xs="6" md="3">
                                        <div className="nl-footer-info">
                                            <h5 className="mb-3 mb-md-4">Utility Pages</h5>
                                            <ul className="row gy-1 gy-sm-3">
                                                <li className="col-12">
                                                    <Link className="fs-7 text-capitalize" to="/auth/login">Login</Link>
                                                </li>
                                                <li className="col-12">
                                                    <Link className="fs-7 text-capitalize" to="/auth/sign-up">Sign
                                                        up</Link>
                                                </li>
                                                <li className="col-12">
                                                    <Link className="fs-7 text-capitalize" to="/auth/reset-password">Reset
                                                        Password</Link>
                                                </li>
                                                <li className="col-12">
                                                    <Link className="fs-7 text-capitalize" to="/auth/forgot-password">Forgot
                                                        Password</Link>
                                                </li>
                                                <li className="col-12">
                                                    <Link className="fs-7 text-capitalize" to="/404">404 Not
                                                        Found</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="8" lg="4">
                                <div className="nl-footer-brand">
                                    <div className="nl-footer-brand-info">
                                        <div className="nl-footer-logo">
                                            <NioBrand logo={logoName} variant={logoVariant}/>
                                        </div>
                                        <p className="mb-0">Prime Target is a powerful tool designed for digital marketing, including all the important features.</p>
                                    </div>
                                    <ul className="nl-footer-social pt-5 pt-lg-6">
                                        <li>
                                            <NioMedia href="#" border rounded="circle" size="sm" icon="facebook-f"
                                                      variant="primary"/>
                                        </li>
                                        <li>
                                            <NioMedia href="#" border rounded="circle" size="sm" icon="twitter"
                                                      variant="primary"/>
                                        </li>
                                        <li>
                                            <NioMedia href="#" border rounded="circle" size="sm" icon="linkedin"
                                                      variant="primary"/>
                                        </li>
                                        <li>
                                            <NioMedia href="#" border rounded="circle" size="sm" icon="telegram"
                                                      variant="primary"/>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="nl-footer-bottom py-5 pb-lg-7">
                    <Container>
                        <Row className="nl-footer-content align-items-center justify-content-between">
                            <Col lg="6" className="px-0">
                                <p className="fs-7 text-center text-lg-start">&copy; 2023 - <span
                                    id="currentYear"> {new Date().getFullYear()} </span>
                                    <Link className="text-primary fw-bold" to="/">Prime Target</Link>. All Rights Reserved.
                                </p>
                            </Col>
                            <Col lg="6" className="px-0">
                                <ul className="nl-footer-copyright justify-content-center justify-content-lg-end">
                                    <li>
                                        <Link className="fs-7 text-capitalize" to="#">All Rights</Link>
                                    </li>
                                    <li>
                                        <Link className="fs-7 text-capitalize" to="#">Terms & conditions</Link>
                                    </li>
                                    <li>
                                        <Link className="fs-7 text-capitalize" to="#">Privacy Policy</Link>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>

        </footer>
    )
}