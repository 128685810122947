import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';


// layout 
import AppLayout from '../../../layouts/AppLayout/AppLayout';

// components
import { Button, Icon, NioSection, NioMedia, NioCard, NioSubscribeField, NioBrand } from '../../../components';

function index() {
  return (
    <AppLayout title="About">

      {/*  Story Section Start  */}
      <NioSection className="pt-120 pt-lg-160" masks={["blur-1 left center"]}>
        <Row className="justify-content-center">
          <Col lg="8" xl="6">
            <div className="nl-section-head text-center">
              <span className="nl-section-subtitle mb-2">Our Story</span>
              <h2 className="nl-section-title">The Story of Prime Target</h2>
              <p className="fs-6 mb-0" >
                Discover the inspiring story behind Prime Target - how a vision became a reality, empowering individuals and businesses to thrive in the digital world.
              </p>
              <ul className="d-flex gap-3 flex-wrap align-items-center justify-content-center pt-5">
                <li>
                  <Button href="/auth/sign-up" className="btn-primary" label="Join Our Team" />
                </li>
                <li>
                  <Button href="#" className="btn-outline-primary" label="Our Values" />
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </NioSection>
      {/*  Story Section End */}

      {/*  Features Section Start  */}
      <NioSection>
        <NioSection.Content>
          <Row className="gy-5">
            <Col md="6" >
              <NioCard>
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                    <div>
                      <h1 className="text-primary">100%</h1>
                    </div>
                    <div className="m-0">
                      <h4 className="text-capitalize">Company growth</h4>
                      <p className="fs-7"> Unleashing exponential growth through innovation. </p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="6" >
              <NioCard>
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                    <div>
                      <h1 className="text-warning">3+</h1>
                    </div>
                    <div className="m-0">
                      <h4 className="text-capitalize">Team members</h4>
                      <p className="fs-7"> Our talented team members are the driving force behind. </p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="6" >
              <NioCard>
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                    <div>
                      <h1 className="text-danger">50+</h1>
                    </div>
                    <div className="m-0">
                      <h4 className="text-capitalize">Active projects</h4>
                      <p className="fs-7"> We have a diverse range of active projects that are driving. </p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="6" >
              <NioCard>
                <NioCard.Body>
                  <div className="d-flex gap-3 gap-md-4 flex-column flex-lg-row align-items-start align-items-lg-center">
                    <div>
                      <h1 className="text-success">99%</h1>
                    </div>
                    <div className="m-0">
                      <h4 className="text-capitalize">Customer satisfaction</h4>
                      <p className="fs-7"> Customer Satisfaction is our top priority. </p>
                    </div>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/*  Features Section End  */}

      {/*  Journey Section Start  */}
      <NioSection>
        <NioSection.Content>
          <Row className="align-items-lg-end justify-content-lg-between gy-5">
            <Col lg="6">
              <div className="d-flex flex-column" >
                <div className="nl-section-head pb-0">
                  <span className="nl-section-subtitle mb-2">The journey</span>
                  <h2 className="nl-section-title">Journey Towards Success</h2>
                  <p className="mb-0"> The journey towards success for Prime Target has been a remarkable one. It started with a vision to provide businesses with a comprehensive platform that simplifies collaboration and maximizes productivity. With a dedicated team and a customer-centric approach, Prime Target has continuously evolved and improved its offerings to meet the changing needs of businesses. </p>
                </div>
              </div>
            </Col>
            <Col lg="6" xl="5">
              <div className="d-flex flex-column">
                <div className="nl-section-head pb-0">
                  <span className="nl-section-subtitle mb-2">Behind the story</span>
                  <h2 className="nl-section-title">How Prime Target Started</h2>
                  <p className="mb-0"> Prime Target started with a vision to revolutionize the way businesses connect and collaborate. It was founded by a group of passionate individuals who saw the need for a platform that seamlessly integrates different tools and channels, allowing teams to work together more effectively. </p>
                </div>
              </div>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/*  Journey Section End  */}

      {/*  Values Section Start  */}
      <NioSection>
        <NioSection.Head alignX="center">
          <span className="nl-section-subtitle mb-2">our values</span>
          <h2 className="nl-section-title">Core Values We Define</h2>
          <p className="mb-0">Innovation, Collaboration, Excellence. These core values guide us as we strive to deliver exceptional solutions and empower businesses.</p>
        </NioSection.Head>
        <NioSection.Content>
          <Row className="gy-3 gy-lg-5">
            <Col md="6" lg="4" >
              <NioCard className="h-100">
                <NioCard.Body className="d-flex flex-column gap-5 justify-content-between">
                  <NioMedia
                    size="lg"
                    rounded="circle"
                    variant="blue-soft"
                    icon="file-text-fill"
                  />
                  <div>
                    <h5>Result Matter</h5>
                    <p className="fs-7 mb-0"> At Prime Target, we believe that results matter. We are committed to delivering tangible outcomes and measurable impact for our clients. </p>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="6" lg="4" >
              <NioCard className="h-100">
                <NioCard.Body className="d-flex flex-column gap-5 justify-content-between">
                  <NioMedia
                    size="lg"
                    icon="gift"
                    rounded="circle"
                    variant="info-soft"
                  />
                  <div>
                    <h5>Commitment</h5>
                    <p className="fs-7 mb-0">Commitment is one of our core values at Prime Target. We are dedicated to providing exceptional service.</p>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="6" lg="4" >
              <NioCard className="h-100">
                <NioCard.Body className="d-flex flex-column gap-5 justify-content-between">
                  <NioMedia
                    size="lg"
                    rounded="circle"
                    variant="warning-soft"
                    icon="growth-fill"
                  />
                  <div>
                    <h5>Growth</h5>
                    <p className="fs-7 mb-0"> Growth is a fundamental value at Prime Target. We believe in continuously learning, improving, and expanding our capabilities. </p>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="6" lg="4" >
              <NioCard className="h-100">
                <NioCard.Body className="d-flex flex-column gap-5 justify-content-between">
                  <NioMedia
                    size="lg"
                    rounded="circle"
                    icon="users-fill"
                    variant="danger-soft"
                  />
                  <div>
                    <h5>Team Work</h5>
                    <p className="fs-7 mb-0"> Teamwork is a core value at Prime Target. We believe in the power of collaboration and synergy that comes from working together. </p>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="6" lg="4" >
              <NioCard className="h-100">
                <NioCard.Body className="d-flex flex-column gap-5 justify-content-between">
                  <NioMedia
                    size="lg"
                    rounded="circle"
                    icon="bulb-fill"
                    variant="blue-soft"
                  />
                  <div>
                    <h5>Innovation</h5>
                    <p className="fs-7 mb-0"> Innovation is at the heart of Prime Target. We continuously strive to push boundaries and challenge the status quo. </p>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="6" lg="4" >
              <NioCard className="h-100">
                <NioCard.Body className="d-flex flex-column gap-5 justify-content-between">
                  <NioMedia
                    size="lg"
                    rounded="circle"
                    icon="flag-fill"
                    variant="blue-soft"
                  />
                  <div>
                    <h5>Ownership</h5>
                    <p className="fs-7 mb-0"> Ownership is a core value at Prime Target. We believe in taking responsibility for our actions, projects, and outcomes. </p>
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
          </Row>
        </NioSection.Content>

      </NioSection>
      {/*  Values Section Start  */}

      {/*  Team Section Start  */}
      <NioSection>
        <NioSection.Head alignX="center">
          <span className="nl-section-subtitle mb-2">team members</span>
          <h2 className="nl-section-title">The Team Behind Prime Target</h2>
          <p className="mb-0">Meet the dedicated team behind Prime Target, working tirelessly to bring you the best experience.</p>
          <ul className="d-flex gap-3 flex-wrap align-items-center justify-content-center pt-5">
            <li>
              <Button href="/auth/sign-up" className="btn-primary" label="Join Our Team" />
            </li>
            <li>
              <Button href="#" className="btn-outline-primary" label="Learn More" />
            </li>
          </ul>
        </NioSection.Head>

        <NioSection.Content>
          <Row className="gy-5">
            <Col md="6" lg="4" >
              <NioCard className="border-0 bg-blue-soft ">
                <NioCard.Body className="pb-0">
                  <div className="card-title-group align-items-start mb-4">
                    <div className="card-title-group-item">
                      <h4 className="text-capitalize mb-0">Vitali Muladze</h4>
                      <p className="fs-7">CEO &amp; Founder</p>
                    </div>
                    <div className="card-title-group-item">
                      <Link to="#" className="text-dark">
                        <Icon name="fedora fs-2" />
                      </Link>
                    </div>
                  </div>
                  <div className="card-image text-center">
                    <img src="images/team/a.png" alt="team" />
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="6" lg="4" >
              <NioCard className="border-0 bg-green-soft">
                <NioCard.Body className="pb-0">
                  <div className="card-title-group align-items-start mb-4">
                    <div className="card-title-group-item">
                      <h4 className="text-capitalize mb-0">Giorgi Sekhniashvili</h4>
                      <p className="fs-7">CEO &amp; Founder</p>
                    </div>
                    <div className="card-title-group-item">
                      <Link to="#" className="text-dark">
                        <Icon name="b-si fs-2" />
                      </Link>
                    </div>
                  </div>
                  <div className="card-image text-center">
                    <img src="images/team/b.png" alt="team" />
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
            <Col md="6" lg="4" >
              <NioCard className="border-0 bg-yellow-soft">
                <NioCard.Body className="pb-0">
                  <div className="card-title-group align-items-start mb-4">
                    <div className="card-title-group-item">
                      <h4 className="text-capitalize mb-0">Julia Shavkatsishvili</h4>
                      <p className="fs-7">Development</p>
                    </div>
                    <div className="card-title-group-item">
                      <Link to="#" className="text-dark">
                        <Icon name="linkedin-round fs-2" />
                      </Link>
                    </div>
                  </div>
                  <div className="card-image text-center">
                    <img src="images/team/c.png" alt="team" />
                  </div>
                </NioCard.Body>
              </NioCard>
            </Col>
          </Row>
        </NioSection.Content>

      </NioSection>
      {/*  Team Section Start  */}

      {/*  Newsletter Section Start  */}
      <NioSection className="pb-lg-0">
        <Row className="justify-content-center justify-content-lg-between align-items-center gx-0 pb-5 border-bottom border-lighter">
          <Col lg="6" xl="4">
            <div className="text-center text-lg-start pb-5 pb-lg-0">
              <h4 className="text-capitalize">Subscribe to our newsletter</h4>
              <p className="fs-7">Join the 100+ People That Uses Our Product.</p>
            </div>
          </Col>
          <Col md="10" lg="6" xl="5">

            <NioSubscribeField />
          </Col>
        </Row>
      </NioSection>
      {/*  Newsletter Section End  */}


    </AppLayout >
  )
}

export default index
